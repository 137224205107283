.Auth {
  background: #eeeeee;
  width: 100%;
  height: 100vh;

  .FormContainer {
    display: table;
    width: 100%;
    height: 100%;

    .FormSubContainer {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      .Form {
        margin-left: auto;
        margin-right: auto;
        max-width: 70%;
        background: white;
        padding: 40px;
        text-align: center;

        @media (min-width: 960px) {
          max-width: 40%;
        }

        @media (max-width: 395px) {
          max-width: 80%;
          padding: 10px 10px 40px 10px;
        }

        .SubmitButton {
          padding: 10px 40px !important;
          margin-top: 40px !important;
          color: #fff !important;
        }

        .ErrorMessage {
          color: red;
        }
      }

      .Logo {
        max-width: 350px;
        margin: 32px;

        @media (max-width: 395px) {
          max-width: 290px;
        }
      }
    }
  }
}
