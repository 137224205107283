.OutlinedSelectFormControl {
  text-align: left !important;
  width: 100% !important;
  height: 50px !important;
}

.TextFieldInput {
  width: 100%;
}

.Dense {
  margin-top: 16px !important;
}

.Checkbox {
  margin-left: 5% !important;
}

.OutlinedSelect {
  width: 100% !important;
}
