.warehouse-form {
  padding-top: 30px;

  .warehouse-header {
    h4 {
      margin: 0;
    }
  }

  .btn-group {
    @media #{$mobile} {
      display: grid;
      justify-content: center;
    }
  }
}
