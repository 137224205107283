.Wrapper {
  display: inline-block;
  width: 100%;

  .CustomSelect {
    border: 1px solid hsl(0, 0%, 80%) !important;
    width: 160px;
    color: #1470af !important;
    border-radius: 2px !important;
    text-transform: uppercase;
    box-shadow: none !important;
    background: white !important;
    font-size: 14px;
    margin-top: 35px;

    &:hover {
      border: 1px solid hsl(0, 0%, 80%) !important;
    }

    @media (min-width: 960px) {
      font-size: 16px;
    }
  }
  .FullWidth {
    width: 100% !important;
  }

  .CustomOption {
    background-color: transparent !important;
    color: #1470af !important;
    text-align: left;
    text-transform: uppercase;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08) !important;
      color: #1470af !important;
    }
  }

  .CustomMenu {
    z-index: 4 !important;
  }
}
.FullWidthWrapper {
  width: 200px;
}
