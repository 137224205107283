.trip-form {
  padding-top: 30px;

  .trip-header {
    h4 {
      margin: 0;
    }
  }

  .section-title {
    text-align: left;
    margin: 32px 0 24px;
    padding-left: 15px;
  }

  .delivery-orders {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 16px;
    padding-bottom: 24px;

    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .title {
      margin-bottom: 8px;
    }
  }

  .btn-group {
    @media #{$mobile} {
      display: grid;
      justify-content: center;
    }
  }
}

.detail-modal {
  padding: 0 10px 10px;
  background-color: $light-grey;
  display: flex;
  flex-direction: column;

  .close-button {
    align-self: flex-end;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-right: 12px;
    margin-top: 12px;

    svg {
      width: 19px;
      height: 19px;
    }

    line {
      stroke: $dark-light-grey;
      stroke-width: 2.5;
      fill: $dark-light-grey;
    }
  }

  .information-div {
    display: flex;
    flex-direction: row;

    svg {
      height: auto;
    }

    .completed-progress-bar {
      z-index: 1;
    }

    .current-progress-bar {
      z-index: 2;
    }

    .pending-progress-bar {
      z-index: 3;
    }

    .stop-div {
      width: 100%;
      border-radius: 10px;
      background-color: $white;
      padding: 20px;
      width: 95%;
      margin: 0 auto 20px;

      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;

        .courier-name {
          margin: 0;
          font-weight: normal;
          font-size: 16px;
        }

        .badge {
          border-radius: 10px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 10px;
        }

        .load-badge {
          color: $purple;
          background-color: $light-purple;
        }

        .drop-off-badge {
          color: $blue;
          background-color: $sky-blue;
        }

        .transshipment-load-badge {
          color: $transshipment-load;
          background-color: $transshipment-load-background;
        }

        .transshipment-drop-off-badge {
          color: $transshipment-drop-off;
          background-color: $transshipment-drop-off-background;
        }
      }

      .general-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .arrival-time {
          text-align: end;
        }

        .direction {
          font-size: 19px;
          font-weight: 500;
          width: 50%;
          margin-right: 10px;
        }
      }

      .extra-info {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        .navigate-button {
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-right: 20px;
          background-color: #f2faef;
          color: $green;
          font-size: 16px;

          svg {
            width: 29px;
            height: 29px;
            margin-right: 10px;
          }
        }

        .packages {
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-right: 20px;
          background-color: $white;
          color: $dark-light-grey;
          font-size: 16px;

          .package-black,
          .package-green {
            stroke-width: 1;
            width: 29px;
            height: 29px;
            margin-right: 10px;
          }

          .package-black {
            stroke: $dark-grey !important;
            fill: $dark-grey !important;

            path {
              stroke: $dark-grey !important;
              stroke-width: 1;
              fill: $dark-grey !important;
            }
          }

          .package-green {
            stroke: $green !important;
            fill: $green !important;

            path {
              stroke: $green !important;
              stroke-width: 1;
              fill: $green !important;
            }
          }
        }
      }
    }
  }
}

.package-modal {
  padding: 0 10px 10px;
  background-color: $light-grey;
  display: flex;
  flex-direction: column;

  .close-button {
    background-color: transparent;
    align-self: flex-end;
    border: 0;
    cursor: pointer;
    margin-right: 12px;
    margin-top: 12px;

    svg {
      width: 19px;
      height: 19px;
    }

    line {
      stroke: $dark-grey;
      stroke-width: 2.5;
      fill: $dark-grey;
    }
  }

  .package-grid {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 4%;
  }

  .package-wrapper {
    width: 100%;

    .package {
      display: grid;
      grid-template-columns: 55% 41%;
      width: 100%;
      margin: 0 auto 20px;
      gap: 4%;
      background-color: $white;
      padding: 10px;
      border-radius: 10px;

      div {
        min-width: 200px;

        h2 {
          font-size: 26px;
          margin: 0 0 10px 0;
          color: $green;
        }

        span {
          font-size: 18px;
          color: $dark-grey;
        }
      }

      .qr {
        width: fit-content;
      }
    }
  }
}
