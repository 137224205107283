.delivery-order-form,
.package-dialog {
  padding-top: 30px;

  .delivery-order-header {
    h4 {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .go-back {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .package-form {
    padding: 32px;
    padding-top: 0;
  }

  .warehouse-row,
  .customer-row,
  .shipper-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 32px;
  }

  .info {
    font-size: 16px;
  }

  .customer-row {
    margin-top: 16px;
  }

  .import-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }

  .text {
    text-align: left;
    display: flex;
    margin-top: 24px;
  }

  .total {
    display: flex;
    text-align: left;
    margin-top: 32px;
  }

  .left-align {
    display: flex;
    justify-content: left;
  }

  .options {
    button {
      width: 50%;
    }
  }

  .submit-button {
    position: absolute;
    left: 32px;
    bottom: 0;
  }

  .custom-input {
    margin-top: 8px;
  }
}

.package-dialog {
  .package-dialog-title {
    padding-top: 24px;
    padding-bottom: 0;
  }

  .package-dialog-content {
    padding-top: 0;
  }
}

.packages-table {
  margin-top: -48px;
  margin-bottom: 64px;
}
