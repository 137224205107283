.Input {
  width: 100% !important;
}

.submit-button {
  margin: 0 auto 25px auto !important;

  @media #{$mobile} {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
}

.cancel-button {
  margin: 0 auto 25px 10px !important;

  @media #{$mobile} {
    margin-left: 0 !important;
    margin-bottom: 10px !important;
    width: 100% !important;
  }
}
