.Couriers {
  .CardBody {
    padding: 50px !important;

    .Title {
      padding-bottom: 16px;
    }
  }
}

.courier-form,
.shipper-form {
  padding-top: 30px;
  .card-header {
    h4 {
      margin: 0;
    }
  }

  .vehicle-type-select,
  .account-type-select {
    color: rgba(0, 0, 0, 0.87);

    .MuiInputLabel-root {
      color: #aaaaaa !important;
    }

    .MuiSelect-select,
    .MuiSelect-nativeInput {
      border-bottom-color: #aaaaaa !important;
    }

    label.Mui-focused {
      color: #aaaaaa !important;
    }

    .MuiInput-underline.Mui-focused:after {
      border-bottom: 2px solid #7fc15e;
    }
  }

  .section-title {
    text-align: left;
    margin-top: 60px;
    margin-bottom: 0;
    padding-left: 15px;
  }

  .image-form-container {
    width: 285px;
    display: flex;
    align-items: center;
    margin: auto;

    .profile-pic-container-large {
      width: 128px;
      height: 128px;
      margin-right: 30px;

      .profile-pic-large {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
}
